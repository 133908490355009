<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-row>
            <b-col
              v-if="users.length"
              md="4"
            >
              <b-form-group
                label="users"
                label-for="users"
              >
                <v-select
                  v-model="user"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="full_name"
                  :options="users"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de début"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date1"
                  v-model="dateDebut"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group
                label="Date de fin"
                label-for="nom-input"
              >

                <b-form-input
                  id="nom-input"
                  ref="date2"
                  v-model="dateFin"
                  type="date"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <div style="text-align: center">
                <b-button
                  id="toggle-btn"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  v-b-modal.distributeur-modal-prevent-closing
                  variant="outline-primary"
                  class="btn_export ml-2"
                  @click="getLogs"
                >
                  <feather-icon icon="SearchIcon" />
                  Filtrer
                </b-button>
              </div>

            </b-col>
          </b-row>
          <hr>
          <hr>
          <!-- input search -->
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Recherche
                </label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Recherche"
                  type="text"
                  class="d-inline-block"
                />
              </div>
            </b-form-group>
            <!-- primary -->
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="Exporter"
              variant="primary"
              class="ml-2 btn_export"
            >
              <b-dropdown-item @click="exportCSV">
                CSV
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-good-table
            id="list"
            :key="componentKey"
            ref="myTable"
            :columns="columns"
            :rows="logs"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm }"
            :pagination-options="{
              enabled: true,
              perPage:pageLength
            }"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >

              <!-- Column: Status -->
              <span v-if="props.column.field === 'nbr_tournes'">

                {{ props.row.nbr_tournes_ok }} /

              </span>
              <span v-if="props.column.field === 'is_online'">
                <b-badge
                  v-if="props.row.is_online"
                  variant="light-success"
                >
                  En ligne
                </b-badge>
                <b-badge
                  v-if="!props.row.is_online"
                  variant="light-danger"
                >
                  Non active depuis 30min
                </b-badge>
              </span>

              <!-- Column: Action -->

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Affichage de 1 à
                  </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="['3','5','10']"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap "> sur {{ props.total }} élements </span>
                </div>
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import {
  BBadge,
  BButton, BButtonGroup,
  BCard, BCol, BDropdown, BDropdownItem, BFormGroup, BFormInput, BFormSelect, BPagination, BRow,
} from 'bootstrap-vue'

import VueGoodTableVue from '@/views/m-components/listes/VueGoodTableVue.vue'
import { API_URL } from '@/helpers/global-scops'
import vSelect from 'vue-select'
// eslint-disable-next-line import/named
import { generateConcatenatedWord, getBefore3day, getCurrentDate, getFirstDayOfCurrentMonth } from '@/helpers/methodes'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import store from '@/store'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'

export default {
  name: 'MainVue',
  components: {
    VueGoodTable,
    BBadge,
    BButtonGroup,
    BFormInput,
    BPagination,
    BDropdownItem,
    BDropdown,
    BButton,
    vSelect,
    BFormSelect,
    BFormGroup,
    VueGoodTableVue,
    BCard,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  data() {
    return {
      dateDebut: '',
      dateFin: '',
      componentKey: 0,
      user: null,
      users: [],
      logs: [],
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Compte',
          field: 'user_r.full_name',
        },
        {
          label: 'Action',
          field: 'action',
        },
        {
          label: 'Date',
          field: 'created_at',
        },
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  mounted() {
    this.dateDebut = getBefore3day()
    this.dateFin = getCurrentDate()
    this.getLogs()
    this.getUsers()
  },
  methods: {
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.logs.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(','))
        .join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${generateConcatenatedWord(`Actions-utilisateurs-${this.dateDebut}-${this.dateFin}`)}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    filtrer() {
      // const userId = this.user ? this.user.uuid : null
    },
    getLogs() {
      let userParam = ''
      if (this.user !== null) {
        userParam = `user=${this.user.uuid}`
      }
      this.$http.get(`${API_URL}logs/?${userParam}&date_debut=${this.dateDebut}&date_fin=${this.dateFin}`)
        .then(response => {
          this.logs = response.data
          console.log(this.logs)
        })
        .catch(error => {
          console.log(error)
        })
    },
    getUsers() {
      this.$http.get(`${API_URL}users/`)
        .then(response => {
          this.users = response.data
          console.log(this.users)
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>
